/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    var replaceLogo = function() {
        if($(window).width() >= 767) {
            var invertedLogo = $('#main-logo').data('inverted');
            var normalLogo = $('#main-logo').attr('src');

            $('#main-logo').attr('src', invertedLogo);

            $(window).scroll(function() {
                var scroll = $(window).scrollTop();

                if(scroll <= 60) {
                    $('#main-logo').attr('src', invertedLogo);
                } else {
                    $('#main-logo').attr('src', normalLogo);
                }
            });
        }
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                // Change the navbar depending on scroll position
                if($(window).width() >= 767) {
                    $('.main-header').addClass('main-header--initial');

                    $(window).scroll(function() {
                        var scroll = $(window).scrollTop();

                        if(scroll <= 60) {
                            $('.main-header').addClass('main-header--initial');
                            $('body').removeClass('body--scrolled');
                        } else {
                            $('.main-header').removeClass('main-header--initial');
                            $('body').addClass('body--scrolled');
                        }
                    });
                }

                $('.menu-item-has-children a:first-child').not('.sub-menu a').on('click', function(event) {
                    event.preventDefault();
                    console.log($(this));


                    if($(this).is('.active')) {
                        $('.menu-item-has-children a').removeClass('active');
                        $('ul').removeClass('active');
                    } else {
                        $('.menu-item-has-children a').removeClass('active');
                        $('ul').removeClass('active');
                        $(this).addClass('active');
                        $(this).next('ul').addClass('active');
                    }


                });

                // Mobile navigation
                $('.sah-button--navigation').on('click', function(event) {
                    event.preventDefault();
                    $(this).toggleClass('active');
                    $('.main-header__navigation').toggleClass('active');
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                replaceLogo();
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
                if($(window).width() <= 767) {
                    console.log($(this).parents('.front-page-supporter__carousel-item').find('.front-page-supporter__mobile-container'));
                    $('.front-page-supporter__author').appendTo($(this).parents('.front-page-supporter__carousel-item').find('.front-page-supporter__mobile-container'));
                    $('.front-page-supporter__button').appendTo($(this).parents('.front-page-supporter__carousel-item').find('.front-page-supporter__mobile-container'));
                }

                $(".supporters-row").owlCarousel({
                    items: 1,
                    nav: false,
                    dots: true,
                    autoplay: true,
                    smartSpeed: 1000,
                    loop: true
                });

                var frontPageCarousel = $('.featured-case-study__carousel');
                var frontPageSlave = $('.header-background__carousel');

                // This is purely a slave carousel to the one above, allowing us to control the background image.
                // It should do no thinking of its own.
                frontPageSlave.owlCarousel({
                    items: 1,
                    nav: false,
                    dots: true,
                    loop: true,
                    smartSpeed: 700
                });

                var updateFrontPageHeader = function(event) {
                    setTimeout(function() {

                        var position = event.relatedTarget.normalize(event.item.index, true) -2;
                        $('.header-background__carousel').trigger('to.owl.carousel', position);
                        $('.header-background--carousel').height($('.header-background--generic').height());

                    }, 300);
                };



                frontPageCarousel.owlCarousel({
                    items: 1,
                    nav: false,
                    dots: true,
                    autoplay: true,
                    loop: true,
                    smartSpeed: 1000,
                    onInitialized: updateFrontPageHeader,
                    onChanged: updateFrontPageHeader
                });




            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
        'post_type_archive_resource': {
            init: function() {
                replaceLogo();
            }
        },
        'post_type_archive_casestudy': {
            finalize: function() {
                $('#getMorePosts').on('click', function(event) {
                    event.preventDefault();
                    var ids = $(this).data(ids);

                    // Load in the rest of the things via AJAX
                    var data = {
                        'action': 'get_stories',
                        'ids': ids
                    };

                    jQuery.post(ajax_object.ajax_url, data, function(response) {
                        $('.stories-loop').append(response);
                    });

                    $(this).remove();
                });
            }
        },
        'contact': {
            init: function() {
                replaceLogo();
            }
        },
        'get_involved': {
            init: function() {
                replaceLogo();
            }
        },
        'about': {
            init: function() {
                replaceLogo();
            }
        },
        'blog': {
            init: function() {
                replaceLogo();
            }
        },
        'post_type_archive_supporter': {
            init: function() {
                replaceLogo();
            }
        },
        'single_casestudy': {
            init: function() {
                /*
                console.log('hi');
                $('#casestudy-sidebar').affix({
                    offset: {
                        top: ($('.header-background').outerHeight(true) + $('.page-banner-secondary').outerHeight(true)) + 40,
                        bottom: ($('.main-footer').outerHeight(true) + $('.front-page-signup').outerHeight(true)) + 80
                    }
                });

                // Remove '< Starts at Home' from the Quickshare plugin
                $('a').each(function(){

                    this.href = this.href.replace ('+%3C+Starts+at+Home', '');

                });

                $('#casestudy-sidebar').css('width', $('.sidebar').width()); */
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
